import React, { useMemo, useState, useEffect } from 'react';

const chartURL = 'https://cdn.jsdelivr.net/npm/chart.js@4.3.0/dist/chart.umd.js';

const callbacks = [];

const onLoaded = () => {
  callbacks.forEach((cb) => cb());
  // const script = document?.body.querySelector('script[data-name="chart.js"]');
  // if (script) {
  //   script?.dataset?.loaded = 'true';
  // }
};

export const initChart = (onLoad = () => {}) => {
  const script = document.body.querySelector('script[data-name="chart.js"]');

  if (!script) {
    const elScript = document.createElement('script');
    callbacks.push(onLoad);
    elScript.onload = onLoaded;
    elScript.setAttribute('src', chartURL);
    elScript.dataset.name = 'chart.js';

    document.body.appendChild(elScript);
  } else {
    callbacks.push(onLoad);
  }
};

export const ChartContext = (props) => {
  const { name, config, width, height, style = {} } = props;
  const id = useMemo(() => Date.now(), []);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    initChart(() => setReady(true));
  }, []);

  useEffect(() => {
    const el = document.querySelector(`#canvas-${id}-${name}`);
    if (el) {
      const ctx = el;
      //   @ts-ignore
      new Chart(ctx, config);
    }
  }, [ready]);

  if (!ready && !global.Chart) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ width, height, position: 'relative', ...style }}>
      <canvas id={`canvas-${id}-${name}`} />
    </div>
  );
};
