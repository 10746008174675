import { createSelector } from 'reselect';

import { customerReducerNamespace, initialState } from './store';

const getCustomerData = (state) =>
  state[customerReducerNamespace] || initialState;

const getCustomerById = (state, id) => {
  const { customers } = getCustomerData(state);

  return customers.find((customer) => String(customer.id) === String(id));
};

const getCustomers = (state) => state[customerReducerNamespace].customers;

const getAccountFormFields = createSelector(
  getCustomerData,
  ({ accountTypes }) =>
    accountTypes.reduce(
      (object, type) => ({
        ...object,
        [type.name]: type.connect.fields.map((field) => ({
          ...field,
          url: type.connect.url,
        })),
      }),
      {}
    )
);

const getServiceOptions = createSelector(getCustomerData, ({ accountTypes }) =>
  accountTypes.map((type) => ({ label: type.label, value: type.name }))
);

const getResourcesStat = createSelector(
  getCustomerData,
  ({ resourcesStat }) => resourcesStat
);

export const customerSelectors = {
  getCustomerData,
  getCustomers,
  getResourcesStat,
  getServiceOptions,
  getAccountFormFields,
  getCustomerById,
};
