import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  isPoliciesLoading: false,
  policies: [],
  policiesScan: [],
  policyGroupStats: {},
  policyGroupChartData: [],
};

const STORE_NAME = '@policyGroup';

export const policyGroupReducerNamespace = 'policyGroup';

export const policyGroupStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    setIsPoliciesLoading: (state, action) => {
      state.isPoliciesLoading = action.payload;
    },
    setPolicyGroupChartData: (state, action) => {
      state.policyGroupChartData = action.payload;
    },
    setPolicyGroupStats: (state, action) => {
      state.policyGroupStats = action.payload;
    },
    resetState: () => initialState,
  },
});

export const policyGroupReducer = policyGroupStore.reducer;
export const policyGroupActions = policyGroupStore.actions;
