import { prepareQuery, wretch, wretchppl } from '../app';
import { ProgressManager } from '../../packages';

const uploadDiagram = ({ requestModel }) =>
  wretch('tm/diagrams/').formData(requestModel).post().json();

const getDiagrams = (query) => wretch('tm/diagrams/').query(query).get().json();

const getCompanies = (query) => wretch('tm/diagrams/companies/').query(query).get().json();

const getComponents = (query) =>
  wretch('tm/components/').query(query).get().json();

const getComponentsOptions = () =>
  wretch('tm/components/choices/').get().json();

const getDiagramById = (id) => wretch(`tm/diagrams/${id}/`).get().json();
const getDiagramResources = (id, query = {}) => wretch(`tm/diagrams/${id}/resources/`).query(query).get().json();
const getDiagramLoactions = (id, query = {}) => wretch(`tm/diagrams/${id}/locations/`).query(query).get().json();
const getResource = (id) => wretch(`customers/resource/${id}/`).get().json();

const patchDiagramById = (id, data = { related_accounts: [] }) => wretch(`tm/diagrams/${id}/`).patch(data).json();

const deleteDiagrams = (ids) =>
  wretch(`tm/diagrams/bulk-delete/?ids=${ids}`).delete().res();

const deleteComponents = (ids) =>
  wretch(`tm/components/bulk-delete/?ids=${ids}`).delete().res();

const addComponent = ({ id, requestModel }) =>
  wretch(`tm/diagrams/${id}/add-component/`).post(requestModel).res();

const startDiagramParse = ({ id }) =>
  wretch(`tm/diagrams/${id}/parse/`).post({}).res();

const updateComponent = ({ id, requestModel }) =>
  wretch(`tm/components/${id}/`).patch(requestModel).res();

const getReport = ({ id, query }) => wretch(`tm/diagrams/${id}/report/`).query(query).get().json();
const patchReport = (reportId, data) => wretch(`tm/report-entries/${reportId}/`).patch(data).json();
const getReportConfigs = () => wretch('tm/report-configs/').get().json();

const messages = {};
const exportReport = (id, configId) => {
  const key = `${id}_${configId}`;

  if (!messages[key]) {
    const idMessage = ProgressManager.progress(null, 'Downloading', 1000_000);
    messages[key] = idMessage;
  }

  return wretch(`tm/diagrams/${id}/export-report/?config=${configId}`)
    .get()
    .json()
    .then((data) => {
      if (data?.status === 'completed') {
        if (messages[key]) {
          ProgressManager.remove(messages[key]);
          delete messages[key];
        }
      }
      return data;
    });
};
const prepareReport = (id, configId) => wretch(`tm/diagrams/${id}/export-report/?config=${configId}`).post().json();

const getGenericName = ({ requestBody }) =>
  wretch(`tm/components/getgeneric/`).post(requestBody).json();

const createComponentType = ({ requestBody }) =>
  wretch(`tm/component-types/`).post(requestBody).json();

const getUploadingStatus = ({ id }) =>
  wretch(`tm/diagrams/${id}/?fields=stage`).get().json();

const getTmDataPP = ({ ppToolToken }) =>
  wretchppl(`results/${ppToolToken}/tm/`).get().json();

const getTmReportDataPP = ({ ppToolToken, query }) =>
  wretchppl(`results/${ppToolToken}/tm/report/`).query(query).get().json();

/*
  Dashboard query filters:
  teams: 1,2,3...
  users: 1,2,3...
  diagrams=1,2...
  range=week|month
*/
const dashboardQueryDefault = {teams: '', users: '', diagrams: '', range: ''}
const dashboardMostCommonThreats = (query = dashboardQueryDefault) => wretch('tm/dashboard/most-common-threats/').query(query).get().json();
const dashboardMostNonCompliantControls = (query = dashboardQueryDefault) => wretch('tm/dashboard/most-non-compliant-controls/').query(query).get().json();
const dashboardMostUsedComponents = (query = dashboardQueryDefault) => wretch('tm/dashboard/most-used-components/').query(query).get().json();
const dashboardStatusProgressCompliance = (query = dashboardQueryDefault) => wretch('tm/dashboard/status-progress-compliance/').query(query).get().json();

/* Diagrams */
const createManualDiagram = ({filename = ""}) => wretch(`tm/manual-diagramming/`).post({filename}).json();
const updateManualDiagram = (diagramId, { manual_data = {}, components = [], links = [] }) =>
  wretch(`tm/manual-diagramming/${diagramId}/save-structure/`).post({ manual_data, components, links }).json();
const getManualDiagram = (diagramId) => wretch(`tm/manual-diagramming/${diagramId}/`)
    .get()
    .json()
    // crash prevention
    .catch(err => err);
const getManualDiagramComponents = (diagramId, page) =>
  wretch(`tm/manual-diagramming/${diagramId}/components/`).query(prepareQuery({ page })).get().json();
const getManualDiagramLibrary = (service, search, page) =>
  wretch(`tm/manual-diagramming/library/`).query(prepareQuery({ service, search, page })).get().json();
const generateDiagram = (account_id, filename) => wretch(`tm/automated-diagramming/`).post({ account_id, filename }).json();

export const threatModelingApi = {
  getReport,
  patchReport,
  getDiagrams,
  startDiagramParse,
  getGenericName,
  createComponentType,
  getCompanies,
  addComponent,
  getComponents,
  exportReport,
  prepareReport,
  getReportConfigs,
  getComponentsOptions,
  updateComponent,
  deleteDiagrams,
  getDiagramById,
  getDiagramResources,
  getDiagramLoactions,
  getResource,
  patchDiagramById,
  uploadDiagram,
  getUploadingStatus,
  getTmDataPP,
  getTmReportDataPP,
  deleteComponents,
  dashboardMostCommonThreats,
  dashboardMostNonCompliantControls,
  dashboardMostUsedComponents,
  dashboardStatusProgressCompliance,
  createManualDiagram,
  updateManualDiagram,
  getManualDiagram,
  getManualDiagramComponents,
  getManualDiagramLibrary,
  generateDiagram,
};
