import { NotificationManager } from 'react-notifications';

import {
  ProgressManager,
  modalActions,
  setPagination,
  usePage,
  useQuery, setQuery,
} from '../../packages';

import { widgetBaseUrl } from '../app';

import { handleError } from '../../packages/utils/handleError';

import { componentsModalName } from '../../pages/diagram-result/components';

import { threatModelingApi } from './api';
import { threatModelingActions } from './store';
import { DiagramTypesByFileType } from './consts';
import { threatModelingSelectors } from './selectors';

const getDiagrams = (query) => async (dispatch) => {
  try {
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('diagrams');
    dispatch(threatModelingActions.setIsLoading(true));
    const { results, ...pagination } = await threatModelingApi.getDiagrams({
      page: page + 1,
      ...otherQueryParams,
      ...rest,
    });

    dispatch(threatModelingActions.setDiagrams(results));

    setPagination('diagrams', { ...pagination, page });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(threatModelingActions.setIsLoading(false));
  }
};

const getCompanies = () => async (dispatch) => {
  try {
    dispatch(threatModelingActions.setIsLoading(true));
    const _results = await threatModelingApi.getCompanies({});
    const results = _results
      ? _results.map((cmp) => ({
          label: cmp.name,
          value: cmp.pk,
        }))
      : [];
    results.unshift({ label: '<All>', value: undefined });
    dispatch(threatModelingActions.setCompanies(results));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(threatModelingActions.setIsLoading(false));
  }
};

const getReport =
  ({ id }, isManualClick) =>
  async (dispatch) => {
    try {
      if (id === undefined) {
        return;
      }
      const report = await threatModelingApi.getReport({ id });

      dispatch(threatModelingActions.setReport(report.results));
      dispatch(threatModelingActions.setWasChanged(false));

      if (isManualClick) {
        const element = document.getElementById('components-report');
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsLoading(false));
    }
  };

const getReportConfigs = () => async (dispatch) => {
  try {
    const reportConfigs = await threatModelingApi.getReportConfigs();

    dispatch(threatModelingActions.setReportConfigs(reportConfigs));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(threatModelingActions.setIsLoading(false));
  }
};

const exportReport =
  ({ id, query }) =>
  async () => {
    try {
      await threatModelingApi.exportReport(id, query?.config);
    } catch (e) {
      console.error(e);
    }
  };

const startDiagramParse =
  ({ id }) =>
  async () => {
    try {
      await threatModelingApi.startDiagramParse({
        id,
      });
    } catch (e) {
      console.error(e);
    }
  };

const getComponents = (query, id) => async (dispatch) => {
  try {
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('components');

    const { results, ...pagination } = await threatModelingApi.getComponents({
      diagram: id,
      page: page + 1,
      ...rest,
      ...otherQueryParams,
    });

    dispatch(threatModelingActions.setComponents(results));

    setPagination('components', { ...pagination, page });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(threatModelingActions.setIsLoading(false));
  }
};

const getComponentsOptions = () => async (dispatch) => {
  try {
    const componentsOptions = await threatModelingApi.getComponentsOptions();

    dispatch(threatModelingActions.setComponentsOptions(componentsOptions));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(threatModelingActions.setIsLoading(false));
  }
};

const deleteDiagrams =
  ({ ids, company }) =>
  async (dispatch) => {
    try {
      dispatch(threatModelingActions.setIsLoading(true));
      await threatModelingApi.deleteDiagrams(ids.join(','));

      NotificationManager.success(
        `${ids.length > 1 ? 'Diagrams were' : 'Diagram was'} deleted`
      );
      const pageFromStorage = usePage('diagrams');
      const query = useQuery('diagrams');

      const requestParams = {
        ordering: '-created_at',
        ...query,
        page: pageFromStorage,
      };

      if (company) {
        requestParams.company = company;
      }

      await dispatch(getDiagrams(requestParams));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsLoading(false));
    }
  };

const getGenericForBaseType = async (component_type_id) =>
  await threatModelingApi.getGenericName({
    requestBody: { component_type_id },
  });

const createComponentType = async (name) =>
  await threatModelingApi.createComponentType({
    requestBody: { name },
  });

const addOrUpdateComponents =
  ({ id, requestModel }) =>
  async (dispatch, getState) => {
    try {
      const { currentDiagram } = threatModelingSelectors.getThreatModelingData(
        getState()
      );

      dispatch(threatModelingActions.setIsComponentUpdating(true));
      if (id) {
        await threatModelingApi.updateComponent({
          id,
          requestModel,
        });
        NotificationManager.success('Component was updated');
      } else {
        await threatModelingApi.addComponent({
          id: currentDiagram?.id,
          requestModel,
        });
        NotificationManager.success('Component was created');
      }
      dispatch(
        modalActions.setModalIsOpen({
          name: componentsModalName,
          isOpen: false,
        })
      );
      dispatch(threatModelingActions.setWasChanged(true));

      const page = usePage('components');
      await dispatch(getComponents({ page, ordering: 'label' }, currentDiagram.id));
      await dispatch(getDiagramById({ id: currentDiagram.id }));
      await dispatch(getReport({ id: currentDiagram.id }));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsComponentUpdating(false));
    }
  };

const deleteComponents =
  ({ ids }) =>
  async (dispatch, getState) => {
    try {
      dispatch(threatModelingActions.setIsLoading(true));

      await threatModelingApi.deleteComponents(ids.join(','));

      NotificationManager.success(
        `${ids.length > 1 ? 'Components were' : 'Component was'} deleted`
      );
      const { currentDiagram } = threatModelingSelectors.getThreatModelingData(
        getState()
      );

      const page = usePage('components');

      dispatch(threatModelingActions.setWasChanged(true));
      await dispatch(getComponents({ page }, currentDiagram.id));
      await dispatch(getDiagramById({ id: currentDiagram.id }));
      await dispatch(getReport({ id }));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsLoading(false));
    }
  };

const getDiagramById =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(threatModelingActions.setIsLoading(true));

      const diagram = await threatModelingApi.getDiagramById(id);

      dispatch(threatModelingActions.setCurrentDiagram(diagram));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsLoading(false));
    }
  };

const getDiagramByToken =
  ({ ppToolToken }) =>
  async (dispatch) => {
    try {
      dispatch(threatModelingActions.setIsLoading(true));
      const diagram = await threatModelingApi.getTmDataPP({ ppToolToken });
      diagram.file = `${widgetBaseUrl}${diagram.file}`;
      diagram.preview = `${widgetBaseUrl}${diagram.preview}`;
      dispatch(threatModelingActions.setCurrentDiagram(diagram));
      const report = await threatModelingApi.getTmReportDataPP({ ppToolToken });
      dispatch(threatModelingActions.setReport(report.report));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(threatModelingActions.setIsLoading(false));
    }
  };

const initDiagrams =
  ({ company }) =>
  async (dispatch) => {
    try {
      dispatch(getCompanies());
      const requestParams = { ordering: '-created_at' };
      setQuery('diagrams', { ordering: '-created_at' });

      if (company) {
        requestParams.company = company;
      }

      dispatch(getDiagrams(requestParams));
    } catch (e) {
      console.error(e);
    }
  };

const initDiagramResult =
  ({ id, ppToolToken }) =>
  async (dispatch) => {
    if (ppToolToken) {
      try {
        await dispatch(getDiagramByToken({ ppToolToken }));
      } catch (e) {
        console.error(e);
      }
      return;
    }
    try {
      await dispatch(getDiagramById({ id }));
      await dispatch(getComponents({ ordering: 'label' }, id));
      await dispatch(getReport({ id }));
      await dispatch(getComponentsOptions());
      await dispatch(getReportConfigs());
    } catch (e) {
      console.error(e);
    }
  };

const uploadDiagram =
  ({ file, company }) =>
  async (dispatch) => {
    const notification = ProgressManager.progress(null, 'Uploading', 1000_000);

    try {
      const type = file.name.split('.').at(-1).toLowerCase();

      const diagramType = DiagramTypesByFileType[type];

      if (!diagramType) {
        NotificationManager.error(
          `This file format is not supported(.${Object.keys(
            DiagramTypesByFileType
          ).join(', .')})`
        );

        return;
      }

      const requestModel = {
        type: diagramType,
        file,
      };

      if (company) {
        requestModel.company = company;
      }

      const { id } = await threatModelingApi.uploadDiagram({ requestModel });
      dispatch(
        updateUploadingStatus({
          notificationId: notification,
          diagramId: id,
          company,
        })
      );
    } catch (e) {
      handleError(e);
    }
  };

const updateUploadingStatus =
  ({ notificationId, diagramId, company }) =>
  async (dispatch) => {
    try {
      setTimeout(async () => {
        const { stage } = await threatModelingApi.getUploadingStatus({
          id: diagramId,
        });

        if (stage === 'parsed' || stage === 'parse_error') {
          NotificationManager.success('Successfully uploaded');
          const query = useQuery('diagrams');

          const requestParams = {
            ordering: '-created_at',
            ...query,
          };

          if (company) {
            requestParams.company = company;
          }

          dispatch(getDiagrams(requestParams));
          ProgressManager.remove(notificationId);
          return;
        }

        dispatch(updateUploadingStatus({ notificationId, diagramId, company }));
      }, 2000);
    } catch (e) {
      handleError(e);
      ProgressManager.remove(notificationId);
    }
  };

export const threatModelingService = {
  getDiagrams,
  exportReport,
  startDiagramParse,
  getReport,
  getDiagramById,
  getComponents,
  getComponentsOptions,
  initDiagramResult,
  initDiagrams,
  uploadDiagram,
  addOrUpdateComponents,
  createComponentType,
  getGenericForBaseType,
  deleteDiagrams,
  deleteComponents,
};
