import React, { memo, useCallback, useMemo, useState } from 'react';

import Select from 'react-select';

import { FormControl, FormHelperText } from '@mui/material';

import { colors } from '../../../theme';

import { FourthSelectLabel, WrapperList } from './styled';
import { selectStyles } from './SecondarySelect';

export const fourthSelectStyles = {
  ...selectStyles,
  control: (styles) => ({
    ...styles,
    borderRadius: 8,
    boxShadow: 0,
    background: colors.secondaryBackground,
    border: `1px solid ${colors.secondaryBorderColor}`,
    fontSize: '16px',
    minHeight: '40px',
    lineHeight: 1,
    color: colors.textColor,

    cursor: 'pointer',
    ':hover': {
      border: `1px solid ${colors.secondaryBorderColor}`,
    },
    ':focus': {
      border: `1px solid ${colors.secondaryBorderColor}`,
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const FourthSelect = memo(
  ({ label, error, helperText, styles, onChange, name, ...rest }) => {
    const onChangeHandler = useCallback(
      (value) => {
        onChange({
          target: {
            name,
            value,
          },
        });
      },
      [name]
    );

    return (
      <FormControl error={error} sx={styles}>
        {!!label && (
          <FourthSelectLabel error={error}>{label}</FourthSelectLabel>
        )}
        <Select
          styles={fourthSelectStyles}
          components={{ IndicatorSeparator: null }}
          onChange={onChangeHandler}
          menuPortalTarget={document.body}
          {...rest}
        />
        <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

export const FourthSelectLazy = memo(
  ({ label, error, helperText, styles, onChange, name, value, placeholder, intersectionRef, onSearch, ...rest }) => {
    const onChangeHandler = useCallback(
      (val) => {
        onChange({
          target: {
            name,
            value: val,
          },
        });
      },
      [name]
    );

    const [focused, setFocused] = useState(false);

    const onFocus = useCallback(() => {
      setFocused(true);
    }, []);

    const onBlur = useCallback(() => {
      if (focused) {
        setFocused(false);
      }
    }, [focused]);

    const CustomMenuList = useCallback(
      ({ innerRef, innerProps, maxHeight, children, ...rest }) => {
        return (
          <WrapperList ref={innerRef} {...innerProps} onClick={onBlur}>
            {children}
            <div ref={intersectionRef} style={{ height: 20, width: '100%' }} />
          </WrapperList>
        );
      },
      [intersectionRef, onBlur]
    );



    const components = useMemo(
      () => ({
        IndicatorSeparator: null,
        MenuList: CustomMenuList,
      }),
      [CustomMenuList]
    );

    return (
      <FormControl error={error} sx={styles}>
        {!!label && <FourthSelectLabel error={error}>{label}</FourthSelectLabel>}
        <Select
          styles={fourthSelectStyles}
          onChange={onChangeHandler}
          menuPortalTarget={document.body}
          value={!focused ? value : ''}
          {...rest}
          placeholder={placeholder}
          filterOption={() => true}
          onInputChange={onSearch}
          onFocus={onFocus}
          onBlur={onBlur}
          components={components}
        />
        <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);