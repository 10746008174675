import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  isComponentUpdating: false,
  companyValue: undefined,
  companies: [],
  diagrams: [],
  selectedDiagrams: [],
  selectedComponents: [],
  components: [],
  currentDiagram: null,
  report: null,
  componentsOptions: null,
  wasChanged: false,
  reportConfigs: [],
};

const STORE_NAME = '@threatModeling';

export const threatModelingReducerNamespace = 'threatModeling';

export const threatModelingStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDiagrams: (state, action) => {
      state.diagrams = action.payload;
    },
    setCompanyValue: (state, action) => {
      state.companyValue = action.payload;
    },
    setCompanies: (state, action) => {
      const results = action.payload;
      state.companies = results;
    },
    setSelectedDiagrams: (state, action) => {
      state.selectedDiagrams = action.payload;
    },
    setCurrentDiagram: (state, action) => {
      state.currentDiagram = action.payload;
    },
    setSelectedComponents: (state, action) => {
      state.selectedComponents = action.payload;
    },
    setReport: (state, action) => {
      state.report = action.payload;
    },
    setReportConfigs: (state, action) => {
      state.reportConfigs = action.payload;
    },
    setComponents: (state, action) => {
      state.components = action.payload;
    },
    setComponentsOptions: (state, action) => {
      state.componentsOptions = action.payload;
    },
    setWasChanged: (state, action) => {
      state.wasChanged = action.payload;
    },
    setIsComponentUpdating: (state, action) => {
      state.isComponentUpdating = action.payload;
    },
    resetState: () => initialState,
  },
});

export const threatModelingReducer = threatModelingStore.reducer;
export const threatModelingActions = threatModelingStore.actions;
