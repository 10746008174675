import { createSelector } from 'reselect';

import { initialState, policyGroupReducerNamespace } from './store';

const getPolicyGroupData = (state) =>
  state[policyGroupReducerNamespace] || initialState;

const getPolicyById = (state, id) => {
  const { policies } = getPolicyGroupData(state);

  return policies.find((policy) => String(policy.id) === String(id));
};

const getPolicyScanById = (state, id) => {
  const { policiesScan } = getPolicyGroupData(state);

  return policiesScan.find((policy) => String(policy.id) === String(id));
};

const getViolatedPolicesStats = createSelector(
  getPolicyGroupData,
  ({ policyGroupStats }) => {
    const { policies_bad } = policyGroupStats ?? {};
    const { high, low, medium, unset } = policyGroupStats.severity ?? {};

    return {
      total: policies_bad,
      highPercent: policies_bad && high ? (high * 100) / policies_bad : 0,
      lowPercent: policies_bad && low ? (low * 100) / policies_bad : 0,
      mediumPercent: policies_bad && medium ? (medium * 100) / policies_bad : 0,
      unsetPercent: policies_bad && unset ? (unset * 100) / policies_bad : 0,
      high: high ?? 0,
      low: low ?? 0,
      medium: medium ?? 0,
      unset: unset ?? 0,
    };
  }
);

export const policyGroupSelectors = {
  getPolicyGroupData,
  getPolicyById,
  getPolicyScanById,
  getViolatedPolicesStats,
};
