import React from 'react';

import DropdownMenu from '../packages/components/dropdown-menu';
import { SecondaryButton } from '../packages';
import IconMoreVertical from '../packages/icons/IconMoreVertical';

const TeamMainMenu = (props) => {
  const { onOpenRemoveMember, onOpenResend, onOpenBlock } = props;
  return (
    <DropdownMenu
      data={[
        { label: 'Remove from team', onClick: onOpenRemoveMember },
        { label: 'Re-send invite', onClick: onOpenResend },
        { label: 'Block user', onClick: onOpenBlock },
      ]}
    >
      <SecondaryButton style={{ minWidth: 'unset', width: 40, height: 40, borderWidth: 2 }}>
        <IconMoreVertical />
      </SecondaryButton>
    </DropdownMenu>
  );
};

export default TeamMainMenu;
