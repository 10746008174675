import React, { Children, memo, useEffect, useMemo } from 'react';

import { Formik } from 'formik';

import { nanoid } from '@reduxjs/toolkit';

import { FormWrapper } from './styled';

export const Form = memo(
  ({
    validationSchema,
    initialValues,
    onSubmit,
    children,
    onChange,
    conditionField,
    isLoading,
    fromWrapperStyle,
    customFormWrapper: CustomFormWrapper,
    ...rest
  }) => (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...rest}
    >
      {(context) => {
        const {
          isSubmitting,
          values,
          handleChange,
          errors,
          touched,
          validateForm,
          setFieldValue,
          setValues,
        } = context;
        const formattedChildren = Children.toArray(children).reduce((accum, child) => {
          if (child?.length) return [...accum, ...child];

          return [...accum, child];
        }, []);

        const buttons = useMemo(
          () =>
            formattedChildren.filter(
              (child) =>
                child?.props?.type === 'submit' ||
                child?.props?.component === 'button'
            ),
          [children]
        );

        const onChangeHandler = (e) => {
          handleChange(e);

          if (onChange) onChange(e.target.name, e.target.value, e, setFieldValue, context);
        };

        useEffect(() => {
          validateForm(values);
          setValues({
            ...initialValues,
            ...values,
          });
        }, [validationSchema]);

        const Wrapper = CustomFormWrapper || FormWrapper;

        return (
          <Wrapper style={fromWrapperStyle}>
            {formattedChildren.map((child) =>
              child?.props?.name || child?.props?.component === 'field' ? React.cloneElement(child, {
                ...child.props,
                value: values[child.props.name] || '',
                onChange: onChangeHandler,
                key: child.props.name,
                disabled: child.props.disabled ?? isLoading ?? isSubmitting,
                error:
                  touched[child.props.name] &&
                  Boolean(errors[child.props.name]),
                helperText:
                  touched[child.props.name] && errors[child.props.name],
                [conditionField]: conditionField
                  ? values[conditionField]
                  : null,
              }) : (child?.props?.type === 'submit' ||
              child?.props?.component === 'button' ? null : child))}
            {buttons.map((child) =>
              React.cloneElement(child, {
                ...child.props,
                key: nanoid(10),
                disabled: isLoading ?? isSubmitting,
              })
            )}
          </Wrapper>
        );
      }}
    </Formik>
  )
);
