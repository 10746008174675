import React, { memo } from 'react';

import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Redirect } from 'react-router';

import { appSelectors } from './entities';
import { PathNames } from './consts';

export const RouteWithPermissions = memo(
  ({ component: Component, path, exact, featurePermissions = [] }) => {
    const user = useSelector(appSelectors.getUser);
    const hasAccess =
      featurePermissions.every((feature) => user?.features.includes(feature)) ||
      user.is_superuser;

    return (
      <Route
        exact={exact}
        path={path}
        render={(props) =>
          hasAccess ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: PathNames.home,
              }}
            />
          )
        }
      />
    );
  }
);
