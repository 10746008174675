import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TableCell } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';

import {
  CollapseIcon,
  ExpandIcon,
  ExpandWrapper,
  StyledTableRow,
} from './styled';

export const TableRow = memo(
  ({
    isSelected,
    onRowSelect,
    row,
    id,
    data,
    withoutCheck,
    withCollapse,
    isAllExtand,
  }) => {
    const cells = Object.entries(row);
    const [isExpand, setIsExpand] = useState(false);

    const { enable, is_custom, service, rowBackGround } = data;

    const onRowSelectHandler = useCallback(
      (event) => {
        onRowSelect(event, id);
      },
      [onRowSelect]
    );

    const toggleExpand = useCallback(() => {
      setIsExpand(!isExpand);
    }, [setIsExpand, isExpand]);

    useEffect(() => {
      setIsExpand(isAllExtand);
    }, [isAllExtand]);

    return (
      <StyledTableRow
        key={row.name?.value}
        selected={isSelected}
        isCustom={is_custom}
        rowBackGround={rowBackGround}
      >
        {!withoutCheck && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isSelected}
              onClick={onRowSelectHandler}
              disabled={!enable || service === 'pipeline'}
            />
          </TableCell>
        )}
        {cells?.map(([cellName, cell]) => {
          const Component = cell.component;

          if (cellName === 'data' || cellName === 'id') {
            return null;
          }

          if (cell.value && !Component) {
            return (
              <TableCell
                key={nanoid(10)}
                align={cell.align}
                padding={cell.padding}
              >
                {cell.value}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={nanoid(10)}
              align={cell.align}
              sx={cell.styles ?? {}}
              padding={row.padding}
            >
              <Component
                id={id}
                {...cell}
                {...data}
                cellName={cellName}
                toggleExpand={toggleExpand}
                disabled={!enable}
                isExpand={isExpand}
              />
            </TableCell>
          );
        })}
        {withCollapse && (
          <TableCell
            padding="checkbox"
            sx={{
              verticalAlign: 'baseline',
            }}
          >
            <ExpandWrapper onClick={toggleExpand}>
              {isExpand ? <CollapseIcon /> : <ExpandIcon />}
            </ExpandWrapper>
          </TableCell>
        )}
      </StyledTableRow>
    );
  }
);

TableRow.defaultProps = {
  withoutCheck: false,
};

TableRow.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  withoutCheck: PropTypes.bool,
};
