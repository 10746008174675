import { createSelector } from 'reselect';

import { initialState, standardReducerNamespace } from './store';

const getStandardData = (state) =>
  state[standardReducerNamespace] || initialState;

const getStandardById = (state, id) => {
  const { standards } = getStandardData(state);

  return standards.find((standard) => String(standard.id) === String(id));
};

const getStandardScanById = (state, id) => {
  const { standardsScan } = getStandardData(state);

  return standardsScan.find((standard) => String(standard.id) === String(id));
};

const getPresets = (state) =>
  state[standardReducerNamespace].presets.map(({ name, regs, id }) => ({
    id,
    label: name,
    value: regs,
  }));

const getIsAssignStandardsLoading = (state) =>
  state[standardReducerNamespace].isAssignStandardsLoading;

const getAssignStandards = (state) =>
  state[standardReducerNamespace].assignStandards.map(
    ({ name, pk, active }) => ({
      id: pk,
      label: name,
      active,
    })
  );

const getPolicyGroupById = (state, id) => {
  const { policyGroups } = getStandardData(state);

  return policyGroups.find(
    (policyGroup) => String(policyGroup.id) === String(id)
  );
};

const getPolicyGroupScanById = (state, id) => {
  const { policyGroupsScan } = getStandardData(state);

  return policyGroupsScan.find(
    (policyGroup) => String(policyGroup.id) === String(id)
  );
};

const getViolatedPolicesStats = createSelector(
  getStandardData,
  ({ standardStats }) => {
    const { policies_bad } = standardStats ?? {};
    const { high, low, medium, unset } = standardStats.severity ?? {};

    return {
      total: policies_bad,
      highPercent: policies_bad && high ? (high * 100) / policies_bad : 0,
      lowPercent: policies_bad && low ? (low * 100) / policies_bad : 0,
      mediumPercent: policies_bad && medium ? (medium * 100) / policies_bad : 0,
      unsetPercent: policies_bad && unset ? (unset * 100) / policies_bad : 0,
      high: high ?? 0,
      low: low ?? 0,
      medium: medium ?? 0,
      unset: unset ?? 0,
    };
  }
);

export const standardSelectors = {
  getPresets,
  getStandardData,
  getStandardById,
  getAssignStandards,
  getPolicyGroupById,
  getStandardScanById,
  getPolicyGroupScanById,
  getViolatedPolicesStats,
  getIsAssignStandardsLoading,
};
