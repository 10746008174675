import React from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const PureSelect = styled(MuiSelect)(({ theme }) => ({
  fontFamily: 'Raleway, sans-serif',

  '& .MuiSelect-select': {
    padding: '10px 14px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.colors.activeTextColor,
    '&.Mui-focused fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '& fieldset': {
      borderColor: `${theme.colors.activeTextColor} !important`,

      '&:hover': {
        borderColor: `${theme.colors.activeTextColor} !important`,
      },
    },
  },
}));

const PureInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.colors.activeTextColor,
  fontFamily: 'Raleway, sans-serif',
}));

const PureMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Raleway, sans-serif',
  height: 40,
  padding: '0 6px',
}));

export const Checkmarks = (props) => {
  const {
    options = [{ id: -1, label: 'Not defined' }],
    name,
    label,
    value,
    error,
    helperText,
    renderValue,
    ...rest
  } = props;
  const style = props.style || {};

  return (
    <FormControl sx={{ m: 1, width: '100%', height: '43px', margin: '12px 0 12px 0', ...style }} error={error}>
      <PureInputLabel size='small'>{label}</PureInputLabel>
      <PureSelect
        name={name}
        multiple
        value={value || []}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          if (typeof renderValue === 'function') {
            return renderValue(selected);
          }
          const labels = options.filter((i) => (selected || []).includes(i.id)).map((i) => i.label);
          return labels.join(', ');
        }}
        {...rest}
      >
        {options.map(({ id, label }) => (
          <PureMenuItem key={id} value={id}>
            <Checkbox checked={(value || []).indexOf(id) !== -1} />
            <ListItemText primary={label} />
          </PureMenuItem>
        ))}
      </PureSelect>
    </FormControl>
  );
};
