import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customers: [],
  isCustomerCreating: false,
  isCustomersByPageLoading: false,
  accountTypes: [],
  selectedCustomerIds: [],
  resourcesStat: null,
};

const STORE_NAME = '@customer';

export const customerReducerNamespace = 'customer';

export const customerStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setAccountTypes: (state, action) => {
      state.accountTypes = action.payload;
    },
    setResourcesStat: (state, action) => {
      state.resourcesStat = action.payload;
    },
    setSelectedCustomerIds: (state, action) => {
      state.selectedCustomerIds = action.payload;
    },
    setIsCustomerCreating: (state, action) => {
      state.isCustomerCreating = action.payload;
    },
    setIsCustomersByPageLoading: (state, action) => {
      state.isCustomersByPageLoading = action.payload;
    },
    resetState: () => initialState,
  },
});

export const customerReducer = customerStore.reducer;
export const customerActions = customerStore.actions;
